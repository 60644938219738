


















































































































import { type PropType, defineComponent } from "@vue/composition-api";
import { useQuery } from "@tanstack/vue-query";
import ErrorAlert from "@/components/ErrorAlert.vue";
import MessagesSettings from "@/components/messages/Settings.vue";
import { buttonVariants } from "@/lib/pfg/components/button";
import { cardVariants } from "@/lib/pfg/components/card";
import Badge from "@/lib/pfg/vue2/badge/Badge.vue";
import Card from "@/lib/pfg/vue2/card/Card.vue";
import Skeleton from "@/lib/pfg/vue2/skeleton/Skeleton.vue";
import { queries } from "@/lib/query/queries";
import { cx } from "@/lib/style/cva.config";
import type { RouteProps } from "@/router/types";
import { formatDate } from "@/utils/date";

export default defineComponent({
	components: { ErrorAlert, MessagesSettings, Badge, Skeleton, Card },
	props: {
		memberId: {
			type: Number as PropType<RouteProps<"member">["memberId"]>,
			required: true,
		},
	},
	setup(props) {
		/** FUTURE: Currently disabled as there is no ability to actually create a message */
		const showCreateButton = false;

		const { status, data, error } = useQuery(
			queries.member(props.memberId).notification.scheduled(),
		);

		return {
			props,
			showCreateButton,
			status,
			data,
			error,
			formatDate,
			cx,
			cardVariants,
			buttonVariants,
		};
	},
});
